<template>
  <div>
    <div class="linkman">
      <div>
        <div style="color: #156ED0; font-weight: bold; flex: 1">用户信息</div>
        <div>
          <el-button
            v-if="linkmanFlag"
            plain
            size="mini"
            @click="linkmanFlag = false"
            >编辑</el-button
          >
          <div v-else>
            <el-button plain type="primary" size="mini" @click="saveLinkMan"
              >保存</el-button
            >
            <el-button plain size="mini" @click="closeLinkman">取消</el-button>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>账户昵称</div>
          <div>
            <span v-if="linkmanFlag">{{ nickName }}</span>
            <el-input v-model="nickName" v-else maxlength="5"></el-input>
          </div>
        </div>
        <div>
          <div>绑定手机号</div>
          <div>
            <span v-if="linkmanFlag">{{ mobile }}</span>
            <el-input v-model="mobile" v-else disabled></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="unit">
      <div>
        <div style="color: #156ED0; font-weight: bold; flex: 1">单位信息</div>
        <div>
          <el-button v-if="unitFlag" plain size="mini" @click="unitFlag = false"
            >编辑</el-button
          >
          <div v-else>
            <el-button plain type="primary" size="mini" @click="saveUnit"
              >保存</el-button
            >
            <el-button plain size="mini" @click="closeUnit">取消</el-button>
          </div>
        </div>
      </div>
      <div>
        <div style="width: 33%">
          <div>单位名称</div>
          <div>
            <span v-if="unitFlag">{{ unit.name }}</span>
            <el-input
              disabled
              v-model="unitPost.name"
              v-else
              style="width: auto"
            ></el-input>
          </div>
        </div>
        <div style="width: 33%">
          <div>统一社会信用代码</div>
          <div>
            <span v-if="unitFlag">{{ unit.social_credit_code }}</span>
            <el-input
              disabled
              v-model="unitPost.socialCreditCode"
              v-else
            ></el-input>
          </div>
        </div>
        <div style="width: 33%">
          <div>所属行业</div>
          <div>
            <span v-if="unitFlag">{{ unit.industry_name }}</span>
            <el-select v-model="unitPost.industryName" v-else>
              <el-option
                v-for="item in cities"
                :key="item.id"
                :label="item.title"
                :value="item.title"
              >
                {{ item.title }}
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div>
        <div style="width: 33%">
          <div>注册日期</div>
          <div>
            <span v-if="unitFlag">{{ unit.founding_time }}</span>
            <el-date-picker
              v-else
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :clearable="false"
              prefix-icon=" "
              v-model="unitPost.foundingTime"
              type="date"
              placeholder="选择日期"
              :picker-options="pickerBeginOption"
            >
            </el-date-picker>
          </div>
        </div>
        <div style="width: 33%">
          <div>注册资本</div>
          <div>
            <span v-if="unitFlag">{{ unit.registered_capital }}万元</span>
            <el-input
              v-model="unitPost.registeredCapital"
              v-else
              maxlength="9"
              onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            >
              <template slot="append">万元</template>
            </el-input>
          </div>
        </div>
        <div style="width: 33%">
          <div>法人代表</div>
          <div>
            <span v-if="unitFlag">{{ unit.legalPerson }}</span>
            <el-input
              v-model="unitPost.legalPerson"
              v-else
              maxlength="5"
            ></el-input>
          </div>
        </div>
      </div>
      <div>
        <div>行政区域</div>
        <div style="width: auto">
          <span v-if="unitFlag">{{ getRegin }}</span>
          <el-cascader
            v-else
            v-model="chooseCity"
            :props="props"
            separator="-"
            @change="changeCascader"
          ></el-cascader>
        </div>
      </div>
      <div>
        <div>详细地址</div>
        <div>
          <span v-show="unitFlag">{{ unit.address }}</span>
          <el-input
            v-show="!unitFlag"
            v-model="unitPost.address"
            style="width: 400px"
          ></el-input>
        </div>
      </div>
      <div>
        <div>经营范围</div>
        <div>
          <div
            v-if="unitFlag"
            style="word-wrap: break-word; line-height: 1.5em"
          >
            {{ unit.scope }}
          </div>
          <el-input
            v-else
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="unitPost.scope"
          >
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from "@/network";
import { getMemberId } from "@/assets/public/utils/token";
export default {
  data() {
    return {
      linkmanFlag: true,
      linkman: {},
      unitFlag: true,
      unit: {},
      unitPost: {
        id: 0,
        name: "",
        socialCreditCode: "",
        industryName: "",
        foundingTime: "",
        registeredCapital: 0,
        legalPerson: "",
        province: "",
        city: "",
        district: "",
        address: "",
        scope: "",
      },
      cities: [],
      options: [],
      props: {
        lazy: true,
        lazyLoad: this.lazyLoad,
      },
      chooseCity: [],
      pickerBeginOption: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      nickName: "",
      mobile: "",
    };
  },
  created() {},
  mounted() {
    this.getIndustry().then(() => {
      this.getInformation();
    });
    this.getLoginInfo();
  },
  computed: {
    getRegin() {
      let regin = "";
      if (Array.isArray(this.unit.administrativeDivision)) {
        for (let i = 0; i < this.unit.administrativeDivision.length; i++) {
          if (i === 0) {
            regin = regin + this.unit.administrativeDivision[i].name;
          } else {
            regin = regin + "-" + this.unit.administrativeDivision[i].name;
          }
        }
      }
      return regin;
    },
  },
  methods: {
    async getLoginInfo() {
      const { data: res } = await request({
        method: "POST",
        url: "/pcp/getLoginInfo",
      });
      if (res.code !== 200) return;
      localStorage.setItem("userinfo", JSON.stringify(res.data));
      this.nickName = res.data.nickName;
      this.mobile = res.data.mobile;
    },
    async getInformation() {
      if (getMemberId() !== "") {
        const { data: res } = await request({
          method: "GET",
          url: "/pcp/PersonalCenter/findInformation",
          params: {
            memberId: getMemberId(),
          },
        });
        if (res.code !== 200) return;
        this.unit = res.data;
        this.unitPost.id = this.unit.id;
        this.unitPost.name = this.unit.name;
        this.unitPost.socialCreditCode = this.unit.social_credit_code;
        this.unitPost.industryName = this.unit.industry_name;
        this.unitPost.foundingTime = this.unit.founding_time;
        this.unitPost.registeredCapital = this.unit.registered_capital;
        this.unitPost.legalPerson = this.unit.legalPerson;
        window.sessionStorage.setItem("companyName", this.unit.name);
        this.chooseCity = this.unit.administrativeDivision.map((item) => {
          return item.name;
        });
        for (let i = 0; i < 3; i++) {
          switch (i) {
            case 0:
              if (this.unit.administrativeDivision[i]) {
                this.unitPost.province =
                  this.unit.administrativeDivision[i].name;
              } else {
                this.unitPost.province = "";
              }
              break;
            case 1:
              if (this.unit.administrativeDivision[i]) {
                this.unitPost.city = this.unit.administrativeDivision[i].name;
              } else {
                this.unitPost.city = "";
              }
              break;
            case 2:
              if (this.unit.administrativeDivision[i]) {
                this.unitPost.district =
                  this.unit.administrativeDivision[i].name;
              } else {
                this.unitPost.district = "";
              }
              break;
          }
        }
        this.unitPost.address = this.unit.address;
        this.unitPost.scope = this.unit.scope;
        return Promise.resolve();
      }
    },
    lazyLoad(node, resolve) {
      let id = null;
      if (node.data) {
        id = node.data.post;
      }
      this.getOptions(node, resolve, id);
    },
    getOptions(node, resolve, id) {
      const _this = this;
      setTimeout(() => {
        _this.getCities(node, resolve, id);
      });
    },
    async getCities(node, resolve, id) {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/PersonalCenter/findAllCity",
        params: {
          id,
        },
      });
      this.options = Array.from(new Set(this.options.concat(res.data)));
      const cities = res.data.map((value, i) => ({
        value: value.name,
        label: value.name,
        post: value.id,
        leaf: node.level >= 2,
      }));
      resolve(cities);
    },
    async getIndustry() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/PersonalCenter/findIndustry",
      });
      this.cities = res.data;
    },
    changeCascader() {
      for (let i = 0; i < 4; i++) {
        switch (i) {
          case 0:
            if (this.chooseCity[i]) {
              this.unitPost.province = this.chooseCity[i];
            } else {
              this.unitPost.province = "";
            }
            break;
          case 1:
            if (this.chooseCity[i]) {
              this.unitPost.city = this.chooseCity[i];
            } else {
              this.unitPost.city = "";
            }
            break;
          case 2:
            if (this.chooseCity[i]) {
              this.unitPost.district = this.chooseCity[i];
            } else {
              this.unitPost.district = "";
            }
            break;
        }
      }
    },
    async saveLinkMan() {
      const testreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (this.mobile === "" || testreg.test(this.mobile)) {
        const { data: res } = await request({
          method: "POST",
          url: "/pcp/updateMemberNickName",
          data: { nickName: this.nickName },
        });
        if (res.code !== 200) return this.$message.error("信息编辑失败");
        this.$message({
          message: "昵称编辑成功",
          type: "success",
        });
        this.getLoginInfo();
        this.linkmanFlag = true;
      } else {
        this.$message.error("请输入正确手机号码");
      }
    },
    async saveUnit() {
      const { data: res } = await request(
        {
          method: "PUT",
          url: "/pcp/PersonalCenter/updateCompanyInformation",
          data: this.unitPost,
        },
        "put"
      );
      if (res.code !== 200) return this.$message.error("信息编辑失败");
      this.getInformation();
      this.getIndustry();
      this.unitFlag = true;
    },
    closeLinkman() {
      this.getInformation().then(() => {
        this.linkmanFlag = true;
        console.log();
        this.nickName = JSON.parse(localStorage.getItem("userinfo")).nickName;
      });
    },
    closeUnit() {
      this.getInformation().then(() => {
        this.unitFlag = true;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.linkman {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 20px;
  & > div:first-child {
    margin-bottom: 18px;
    display: flex;
    div {
      align-self: center;
    }
  }
  & > div:nth-child(2) {
    display: flex;
    font-size: 14px;
    & > div {
      width: 263px;
      & > div:first-child {
        color: #8d8d8d;
      }
      & > div:last-child {
        margin-top: 5px;
        height: 30px;
        line-height: 30px;
      }
    }
  }
}
.unit {
  & > div:nth-child(-n + 3) {
    display: flex;
  }
  & > div:first-child {
    margin-bottom: 18px;
    & ~ div {
      font-size: 14px;
    }
    div {
      align-self: center;
    }
  }
  & > div:nth-child(2),
  & > div:nth-child(3) {
    margin-bottom: 30px;
    & > div {
      & > div:first-child {
        color: #8d8d8d;
      }
      & > div:last-child {
        margin-top: 5px;
        height: 30px;
        line-height: 30px;
      }
    }
    & > div:first-child {
      width: 263px;
    }
    & > div:nth-child(2) {
      width: 223px;
    }
  }
  & > div:nth-child(n + 4) {
    margin-bottom: 30px;
    & > div:first-child {
      color: #8d8d8d;
    }
    & > div:last-child {
      margin-top: 5px;
      height: 30px;
      line-height: 30px;
    }
  }
  & > div:last-child {
    div:last-child {
      height: auto;
      line-height: normal;
    }
  }
}
/deep/.el-input__inner {
  height: 30px !important;
  line-height: 30px !important;
  padding: 0 10px !important;
  width: 200px !important;
}
/deep/ .el-input-group {
  display: inline-flex;
}
/deep/.el-input-group__append {
  padding-top: 6px;
  display: inline-table;
}
.el-input-group__prepend {
  background-color: #fff;
  padding: 0 21px;
}

/deep/.el-textarea__inner {
  padding: 0 10px !important;
}
/deep/ .el-button:active {
  background: #156ED0;
}
/deep/.el-button--primary.is-plain:hover {
  background: #156ED0;
  background-color: #156ED0;
}
</style>
