<template>
  <div class="person-status">
    <el-alert title="（以下所填信息，为上一年度数据）" type="info" show-icon :closable="false"></el-alert>
    <div class="title">
      <!--  财务状况区域-->
      <div class="title-btn">
        <div style="color: #156ED0;font-weight: bold;flex: 1;">职称</div>
        <div>
          <el-button v-if="titleFlag" plain size="mini" @click="titleFlag = false">编辑</el-button>
          <div v-else>
            <el-button plain type="primary" size="mini" @click="saveTitle">保存</el-button>
            <el-button plain size="mini" @click="closePerson1">取消</el-button>
          </div>
        </div>
      </div>
      <div class="data-area">
        <!--          数据区域-->
        <div class="data-item" v-for="(item1,index1) in tagsName.professionalName" :key="item1.id">
          <span style="width: 90px">{{ item1.title }}</span>
          <div class="data-item-show" v-if="titleFlag">{{ tagsValue.professionalName[index1] }} <span v-if="index1 === 7">%</span><span v-else>人</span></div>
          <div class="data-item-edit" v-else><el-input v-model="professionalNameData[index1]" style="width: auto;" maxlength="9" oninput="value=value.replace(/^0|[^\d]/g, '')" />&nbsp;<span v-if="index1 === 7">%</span><span v-else>人</span></div>
          <div class="item-checkbox" v-if="titleFlag === false">
            <el-checkbox-group v-model="checkList">
                <template v-if="index1 === 0">
                  <el-checkbox :label="RelateProfessionalName[index1].id">
                    {{RelateProfessionalName[index1].title}}</el-checkbox>
                </template>
                <template v-else>
                  <el-checkbox :label="RelateProfessionalName[index1].id">
                    {{ RelateProfessionalName[index1].title }}</el-checkbox>
                  <el-checkbox :label="RelateProfessionalName[index1+1].id">
                    {{ RelateProfessionalName[index1+1].title }}</el-checkbox>
                </template>
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
    <div class="education">
      <!--  财务状况区域-->
      <div class="title-btn">
        <div style="color: #156ED0;font-weight: bold;flex: 1;">学历</div>
        <div>
          <el-button v-if="educationFlag" plain size="mini" @click="educationFlag = false">编辑</el-button>
          <div v-else>
            <el-button plain type="primary" size="mini" @click="saveEducation">保存</el-button>
            <el-button plain size="mini" @click="closePerson2">取消</el-button>
          </div>
        </div>
      </div>
      <div class="data-area">
        <!--          数据区域-->
        <div class="data-item" v-for="(item1,index1) in tagsName.education" :key="item1.id">
          <span style="width: 90px">{{ item1.title }}</span>
          <div class="data-item-show" v-if="educationFlag">{{ tagsValue.education[index1] }} <span v-if="index1 === 7">%</span><span v-else>人</span></div>
          <div class="data-item-edit" v-else><el-input v-model="educationData[index1]" style="width: auto;" maxlength="9" oninput="value=value.replace(/^0|[^\d]/g, '')" />&nbsp;<span v-if="index1 === 7">%</span><span v-else>人</span></div>
        </div>
      </div>
    </div>
    <div class="rdpersonnel">
      <!--  财务状况区域-->
      <div class="title-btn">
        <div style="color: #156ED0;font-weight: bold;flex: 1;">研发人员</div>
        <div>
          <el-button v-if="rdpersonnelFlag" plain size="mini" @click="rdpersonnelFlag = false">编辑</el-button>
          <div v-else>
            <el-button plain type="primary" size="mini" @click="saverDPersonnel">保存</el-button>
            <el-button plain size="mini" @click="closePerson3">取消</el-button>
          </div>
        </div>
      </div>
      <div class="data-area">
        <!--          数据区域-->
        <div class="data-item" v-for="(item1,index1) in tagsName.RDPersonnel" :key="item1.id">
          <span style="width: 90px">{{ item1.title }}</span>
          <div class="data-item-show" v-if="rdpersonnelFlag">{{ tagsValue.RDPersonnel[index1] }} <span v-if="index1 === 7">%</span><span v-else>人</span></div>
          <div class="data-item-edit" v-else><el-input v-model="RDPersonnelData[index1]" style="width: auto;" maxlength="9" oninput="value=value.replace(/^0|[^\d]/g, '')" />&nbsp;<span v-if="index1 === 7">%</span><span v-else>人</span></div>
        </div>
      </div>
    </div>
    <div class="social">
      <!--  财务状况区域-->
      <div class="title-btn">
        <div style="color: #156ED0;font-weight: bold;flex: 1;">社保在册职工</div>
        <div>
          <el-button v-if="socialFlag" plain size="mini" @click="socialFlag = false">编辑</el-button>
          <div v-else>
            <el-button plain type="primary" size="mini" @click="saveSocial">保存</el-button>
            <el-button plain size="mini" @click="closePerson4">取消</el-button>
          </div>
        </div>
      </div>
      <div class="data-area">
        <!--          数据区域-->
        <div class="data-item" v-for="(item1,index1) in tagsName.registeredEmployees" :key="item1.id">
          <span style="width: 90px">{{ item1.title }}</span>
          <div class="data-item-show" v-if="socialFlag">{{ tagsValue.registeredEmployees[index1] }} <span v-if="index1 === 7">%</span><span v-else>人</span></div>
          <div class="data-item-edit" v-else><el-input v-model="registeredEmployeesData[index1]" style="width: auto;" maxlength="9" onkeyup="this.value = this.value.replace(/[^\d.]/g,'');" />&nbsp;<span v-if="index1 === 7">%</span><span v-else>人</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '../../../../network';
import { getEntityId } from '@/assets/public/utils/token';
export default {
  name: 'personStatus',
  data () {
    return {
      tagsName: {
        professionalName: [],
        education: [],
        RDPersonnel: [],
        registeredEmployees: []
      },
      tagsValue: {
        professionalName: [],
        education: [],
        RDPersonnel: [],
        registeredEmployees: []
      },
      professionalNameData: [],
      educationData: [],
      RDPersonnelData: [],
      registeredEmployeesData: [],
      tagsRelatedName: {
        professionalName: []
      },
      RelateProfessionalName: [],
      tagsRelatedValue: {
        exist: [],
        noExist: []
      },
      checkList: [],
      titleFlag: true,
      educationFlag: true,
      rdpersonnelFlag: true,
      socialFlag: true
    };
  },
  mounted () {
    this.findPersonnelStatusName().then(() => this.findPersonnelStatusValue());
    this.findRelatedLabelsName().then(() => this.findRelatedLabelsValue());
  },
  methods: {
    async findPersonnelStatusName () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findPersonnelStatus'
      });
      if (res.code !== 200) return this.$message.error('信息获取失败');
      this.tagsName = res.data;
      return Promise.resolve();
    },
    async findPersonnelStatusValue () {
      const entityId = getEntityId()
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findPersonnelStatus',
        params: {
          // entityId: getEntityId()
          entityId: entityId
        }
      });
      if (res.code !== 200) return this.$message.error('信息获取失败');
      this.tagsValue = res.data;
      this.educationData = this.tagsValue.education;
      this.professionalNameData = this.tagsValue.professionalName;
      this.registeredEmployeesData = this.tagsValue.registeredEmployees;
      this.RDPersonnelData = this.tagsValue.RDPersonnel;
      return Promise.resolve();
    },
    async findRelatedLabelsName () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findRelatedLabels'
      });
      if (res.code !== 200) return this.$message.error('信息获取失败');
      this.tagsRelatedName = res.data;
      this.RelateProfessionalName = this.tagsRelatedName.professionalName;
      return Promise.resolve();
    },
    async findRelatedLabelsValue () {
      const entityId = getEntityId()
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findRelatedLabels',
        params: {
          // entityId: getEntityId()
          entityId: entityId
        }
      });
      if (res.code !== 200) return this.$message.error('信息获取失败');
      this.tagsRelatedValue = res.data;
      this.checkList = this.tagsRelatedValue.exist.filter(e => {
        let flag = false;
        for (let i = 0; i < this.RelateProfessionalName.length; i++) {
          if (this.RelateProfessionalName[i].id === e.id) {
            flag = true;
            break;
          } else {
            flag = false;
          }
        }
        return flag;
      }).map(e => e.id);
    },
    async saveTitle () {
      const entityId = getEntityId()
      const checkListRequest = [];
      for (let i = 0; i < this.RelateProfessionalName.length; i++) {
        if (this.checkList.indexOf(this.RelateProfessionalName[i].id) > -1) {
          checkListRequest[i] = true;
        } else {
          checkListRequest[i] = false;
        }
      }
      const { data: res } = await request({
        method: 'PUT',
        url: '/pcp/PersonalCenter/updatePersonnelStatusTitle',
        data: {
          // entityId: getEntityId(),
          entityId: entityId,
          str: this.professionalNameData,
          str1: checkListRequest
        }
      });
      if (res.code !== 200) return this.$message.error('编辑失败');
      this.$message.success('编辑成功');
      this.findPersonnelStatusName().then(() => this.findPersonnelStatusValue());
      this.findRelatedLabelsName().then(() => this.findRelatedLabelsValue());
      this.titleFlag = true;
    },
    async saveEducation () {
      const entityId = getEntityId()
      const { data: res } = await request({
        method: 'PUT',
        url: '/pcp/PersonalCenter/updatePersonnelEducation',
        data: {
          // entityId: getEntityId(),
          entityId: entityId,
          doctor: this.educationData[0],
          master: this.educationData[1],
          undergraduate: this.educationData[2],
          juniorCollege: this.educationData[3]
        }
      });
      if (res.code !== 200) return this.$message.error('编辑失败');
      this.$message.success('编辑成功');
      this.findPersonnelStatusValue();
      this.educationFlag = true;
    },
    async saverDPersonnel () {
      const entityId = getEntityId()
      const { data: res } = await request({
        method: 'PUT',
        url: '/pcp/PersonalCenter/updateRDPersonnel',
        data: {
          // entityId: getEntityId(),
          entityId: entityId,
          personnel: this.RDPersonnelData[0]
        }
      });
      if (res.code !== 200) return this.$message.error('编辑失败');
      this.$message.success('编辑成功');
      this.findPersonnelStatusValue();
      this.rdpersonnelFlag = true;
    },
    async saveSocial () {
      const entityId = getEntityId()
      const { data: res } = await request({
        method: 'PUT',
        url: '/pcp/PersonalCenter/updateRDPersonnel',
        data: {
          // entityId: getEntityId(),
          entityId: entityId,
          socialSecurity: this.registeredEmployeesData[0]
        }
      });
      if (res.code !== 200) return this.$message.error('编辑失败');
      this.$message.success('编辑成功');
      this.findPersonnelStatusValue();
      this.socialFlag = true;
    },
    closePerson1 () {
      this.findPersonnelStatusName().then(() => this.findPersonnelStatusValue());
      this.titleFlag = true;
    },
    closePerson2 () {
      this.findPersonnelStatusName().then(() => this.findPersonnelStatusValue());
      this.educationFlag = true;
    },
    closePerson3 () {
      this.findPersonnelStatusName().then(() => this.findPersonnelStatusValue());
      this.rdpersonnelFlag = true;
    },
    closePerson4 () {
      this.findPersonnelStatusName().then(() => this.findPersonnelStatusValue());
      this.socialFlag = true;
    }
  }
};
</script>

<style scoped>
.title, .education, .rdpersonnel, .social {
  padding-bottom: 30px;
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 20px;
}
.title .title-btn,.education .title-btn,.rdpersonnel .title-btn,.social .title-btn {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.data-area {
  margin-top: 12px;
}
.data-area .data-item{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: #8D8D8D;
  height: 24px;
}
.data-area .data-item-show{
  margin-left: 40px;
}
.data-area .data-item-edit{
  margin-left: 40px;
}
.data-area /deep/.el-input__inner{
  height: 24px;
}
 .item-checkbox{
  margin-left: 45px;
  font-size: 14px;
  color: #000000;
}
</style>
