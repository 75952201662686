<template>
  <el-dialog
    title="编辑标签"
    :visible.sync="dialogVisible"
    width="90%"
    @close="closeDialog"
    :close-on-click-modal="false">
    <span style="font-size: 24px; color: #000000">{{ bigTitle.name }}</span>
    <div class="data-area">
      <div class="data-title">
        <div class="data-title-item-normal"
             :class="{'data-title-item-active' : activeTitle === item1.request}"
             v-for="(item1,index1) in tagsMap"
             :key="index1"
             @click="chooseTitle(item1.request)">
          {{ item1.name }}
        </div>
      </div>
      <div class="data-tags">
        <div class="data-tags-item-normal"
             :class="{'data-tags-item-active': chooseTags.indexOf(item2.uuid)>-1}"
             v-for="item2 in tagsName[activeTitle]"
             :key="item2.uuid"
             @click="chooseTag(item2.uuid)">{{ item2.title }}</div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="saveTagsData">保 存</el-button>
    <el-button @click="closeDialog">取 消</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { request } from '../../../../network';
import { getEntityId } from '@/assets/public/utils/token';
export default {
  name: 'honorTagsDialog',
  data () {
    return {
      dialogVisible: this.value,
      chooseTags: [],
      newTagsNameArr: [],
      activeTitle: ''
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    bigTitle: Object,
    tagsName: Object,
    tagsData: Object,
    tagsMap: Array
  },
  computed: {
    getNewTagsName () {
      return 1;
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog');
      this.dialogVisible = false;
      this.chooseTags = [];
      this.$emit('input', this.dialogVisible);
    },
    chooseTitle (request) {
      this.activeTitle = request;
    },
    chooseTag (uid) {
      if (this.chooseTags.indexOf(uid) > -1) {
        this.chooseTags.splice(this.chooseTags.indexOf(uid), 1);
      } else {
        this.chooseTags.push(uid);
      }
    },
    async saveTagsData () {
      const { data: res } = await request({
        method: 'PUT',
        url: '/pcp/PersonalCenter/updateHonoraryQualification',
        data: {
          entityId: getEntityId(),
          type: this.bigTitle.type,
          str: this.chooseTags
        }
      });
      if (res.code !== 200) return this.$message.error('保存失败');
      this.$message.success('保存成功');
      this.$emit('saveSuccess');
      this.dialogVisible = false;
    }
  },
  watch: {
    value (newVal) {
      this.dialogVisible = newVal;
    },
    tagsName (newVal) {
      for (const newValKey in newVal) {
        newVal[newValKey].forEach(e => {
          this.newTagsNameArr.push(e.uuid);
        });
      }
    },
    tagsData (newVal) {
      if (newVal !== null) {
        for (const newValKey in newVal) {
          newVal[newValKey].forEach(e => {
            this.chooseTags.push(e.uuid);
          });
        }
      } else {
        this.chooseTags = [];
      }
    },
    tagsMap (newVal) {
      this.activeTitle = newVal[0].request;
    }
  }
};
</script>

<style scoped>
/deep/.el-dialog__header {
  border-bottom: 1px solid #F0F0F0;
}
.data-area {

}
.data-title {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}
.data-tags {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 450px;
  overflow: auto;
}
.data-title-item-normal {
  box-sizing: border-box;
  width: 100px;
  border: 1px solid #ccc;
  color: #888;
  border-radius: 3px;
  margin-bottom: 10px;
  margin-right: 5px;
  display: inline-block;
  height: 38px;
  line-height: 38px;
  padding: 0 18px;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.data-title-item-active {
  color: #FFFFFF;
  background-color: #3f86fb;
}
.data-tags-item-normal {
  margin-right: 20px;
  margin-bottom: 10px;
  width: 250px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  font-size: 14px;
  border-radius: 2px 0 0 2px;
  background-color: #fff;
  color: #888;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #ccc;
  cursor: pointer;
}
.data-tags-item-normal:hover {
  background-color: #fff;
  border-color: #156ED055;
  color: #156ED0aa;
}
.data-tags-item-active {
  background-image: linear-gradient(to right, #156ED022 , white);
  background-color: #fff;
  border-color: #156ED0;
  color: #156ED0;
}
</style>
